<template>
  <div class="impressum row justify-content-center">
    <div class="homeBtn">
      <a href="/" target="">Home</a>
    </div>
    <div class="col-8 pbSpace">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum'
}
</script>